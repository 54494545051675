<template>
  <div class="header-wrapper">
    <div class="head-logo-item">
      <img class="logo" src="@/assets/imgs/logo.png" />
    </div>
    <el-menu
      class="el-nav"
      mode="horizontal"
      background-color="#009485"
      text-color="#fff"
      active-text-color="#ffd04b"
      :router="true"
    >
      <el-menu-item
        v-for="(item, k) in NavBarRoute"
        :key="k"
        :index="item.name"
        :route="item"
      >
        <template slot="title">
          <i :class="item.meta['icon']" style="color: #fff"></i>
          <span>{{ item.meta["title"] }}</span>
        </template>
      </el-menu-item>
      <el-menu-item @click="login" v-if="!user_info">
        <i class="el-icon-setting" style="color: #fff"></i>
        <span slot="title">登陆</span>
      </el-menu-item>
      <el-submenu index="userInfo" v-if="user_info">
        <template slot="title">
          <p class="user-center">
            <img class="avatar" :src="user_info['headimgurl']" />你好！{{
              user_info["nickname"]
            }}
          </p>
        </template>
        <el-menu-item
          v-for="(item, k) in UserCenterRoute"
          :key="k"
          :index="item.name"
          :route="item"
        >
          <template slot="title">
            <i :class="item.meta['icon']" style="color: #fff"></i>
            <span>{{ item.meta["title"] }}</span>
          </template>
        </el-menu-item>

        <el-menu-item @click="logOut">
          <i class="el-icon-switch-button" style="color: #fff"></i>
          <span slot="title">退出登录</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      navRouter: [],
    };
  },
  name: "header-bar",
  computed: {
    ...mapState("auth", ["user_info"]),
    UserCenterRoute() {
      return this.navRouter.filter((v, k) => {
        return v["meta"]["show4nav"] == "user-center";
      });
    },
    NavBarRoute() {
      return this.navRouter.filter((v, k) => {
        return v["meta"]["show4nav"] == "nav";
      });
    },
  },
  methods: {
    ...mapActions("auth", ["logOut"]),
    Recharge() {
      //暂时替代一下
      console.log("recharge");
    },
    closeDrawer() {
      setTimeout(() => {
        this.drawer = false;
      }, 500);
    },
    login(){
      location.href='/'
    }
  },
  mounted() {
    let navRouter = this.$router.options["routes"].filter((v, i) => {
      return v.name == "pc";
    });

    this.navRouter = navRouter[0]["children"].filter(
      (v, i) => v["meta"]["show4nav"]
    );
  },
};
</script>

<style lang="stylus" scoped>
.header-wrapper {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  color: #000;
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #009485;

  // border-bottom:  solid 1px #e6e6e6
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-bottom: solid 1px #e6e6e6;
  }

  .user-center {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    margin: 0;
    padding-left: 50px;

    .avatar {
      width: 40px;
      height: 40px;
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 100%;
    }
  }

  .el-nav {
    display: inline-block;
    float: right;
  }

  .head-logo-item {
    display: inline-block;
    width: 245px;
    height: 100%;
    position: relative;

    .logo {
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  .more-btn {
    display: inline-block;
    width: 36px;
    height: 50px;
    color: #fff;
    font-size: 36px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
</style>