<template>
  <el-container>
    <el-header><HeaderBar /></el-header>
    <el-main>
      <router-view />
      <div class="login-dialog">
        <el-dialog :visible.sync="loginDialog" width="300px">
          <template slot="title">
            <p style="text-align: center;margin:0;">请使用微信扫码登陆</p>
          </template>
          <div class="qrcode-wrapper">
            <img :src="login_qrcode" class="qrcode"/>
            <img src="@/assets/imgs/logo-qrcode.jpeg" class="qrcode-logo" />
          </div>
        </el-dialog>
      </div>
    </el-main>
  </el-container>
</template>
  
  <script>
import HeaderBar from "./components/HeaderBar";
import { is_weixin } from "@/utils/libs";
import { mapActions } from "vuex";
import { get_login_ercode, login_by_ercode } from "@/api/auth";
export default {
  name: "header-bar",
  components: {
    HeaderBar,
  },
  data() {
    return {
      loginDialog: false,
      login_qrcode: undefined,
      ercodeTimeOut:false,
    };
  },
  methods: {
    ...mapActions("auth", ["getUserInfo"]),
    LoginByScene(scene_str, times, dur) {
      return new Promise((reslove, reject) => {
        if (times > 0) {
          setTimeout(() => {
            login_by_ercode(scene_str).then(({ code, data }) => {
              if (data.ret) {
                reslove(data);
              } else {
                return this.LoginByScene(scene_str, --times, dur)
                  .then(reslove)
                  .catch(reject);
              }
            });
          }, dur);
        } else {
          reslove();
        }
      });
    },
    showQrcode() {
      // 展示二维码登陆
      get_login_ercode().then(({ code, data }) => {
        this.login_qrcode = data["imgUrl"];
        let scene_str = data["scene_str"];
        this.loginDialog = true;
        //轮寻找100次，三秒一次
        this.LoginByScene(scene_str, 20*5, 3000)
          .then(({ token }) => {//成功获取token
            this.getUserInfo(token).then((data) => {
              //登陆成功回到首页
              this.$message.success("登陆成功")
              let path = this.$route["path"];
              this.$router.push({ path });
              this.loginDialog = false
            });
          })
          .catch(() => {//轮训超时
            this.ercodeTimeOut = true
            if(this.loginDialog){//窗口还打开的话继续
               this.showQrcode()
            }
          });
      });
    },
  },
  mounted() {
    let token = this.$route.query["token"];
    let freetrial = this.$route.query["freetrial"] == "True";
    //如果login位真，直接调用微信登陆
    let login = this.$route.query["login"] == "true";
    if (login) {
      this.showQrcode();
    } else {
      if (freetrial) {
        this.$message("恭喜您！已领取3000字体验名额");
      }
      this.getUserInfo(token).then((data) => {
        //登陆成功回到首页
        let path = this.$route["path"];
        if (this.$route.query["token"]) {
          this.$router.push({ path });
        }
      });
    }
  },
  created() {
    // 禁用缩放
    function addMeta() {
      let meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content =
        "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
    setTimeout(addMeta, 3000);

    // 禁用双指放大
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },
};
</script>
  
  <style lang="stylus">
  body {
    margin: 0;
    padding: 0;
    
  }

  .login-dialog {
    .qrcode-wrapper {
      display: block;
      width: 100;
      position: relative;
      width: 100%;

      .qrcode {
        display: block;
        width: 100%;
      }

      .qrcode-logo {
        display: block;
        position: absolute;
        width: 15%;
        top: 50%;
        left: 50%;
        border-radius: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
  }

  .el-header {
    background-color: #B3C0D1;
    color: #333;
    text-align: left;
    line-height: 60px;
    overflow: show;
    background-color: #fff;
    color: #000;
  }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    display: block;
    box-sizing: border-box;
    color: #333;
    display: block;
    position: absolute;
    text-align: left;
    top: 60px;
    bottom: 0px;
    width: 100%;
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgb(244, 244, 245);
  }

  body > .el-container {
    margin-bottom: 40px;
    
  }

  .el-container:nth-child(5) .el-aside, .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .el-main {
    padding: 20px !important;
    padding-top: 40px !important;
  }
</style>
  